  const defaultTheme = {
    breakpoints: {
      s: "only screen and (max-width: 65rem)",
      m: "only screen and (max-width: 70rem)"
    },
    colors: {
      llBlue: "#0EB2DE",
      llOrange: "#E47928",
      h2: "#0EB2DE",
      h3: "#E47928",
    },
    fonts: {
      family: ["Droid Serif", "Times New Roman", "Times", "serif"],
      body: "",
      header: "",
      button: "",
    }
  };

export default defaultTheme;