import React from "react";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar } from '@fortawesome/free-solid-svg-icons'

const llAddress = '1 - 1603 62 Ave SE, Calgary, AB, T2C 0M4'
const addressLinkURL = '//goo.gl/maps/cBzZgRJ9iQc6eoDi6'

const AddressLink = styled.a`
    text-decoration: none;
    color: #2d3b97;
    display: flex;
    justify-content: center;
    align-items: center;
`
const CarIcon = styled(FontAwesomeIcon)`
  color: #E47928;
`


export const AddressWithIcon : React.FC = () => {
    return (<AddressLink href={addressLinkURL}> 
        <CarIcon icon={faCar} size="2x"/> {llAddress}
        </AddressLink>)
}