import React from "react";
import { Section, Title, TableBlock, Table, TableBody, TableRow, TableDetail } from '../../styles/footerStyles'

export const Location: React.FC = () => {
    return (
        <Section>
            <Title>
                Location
            </Title>
            <TableBlock>
                <Table>
                    <TableBody>
                        <TableRow><TableDetail>Location: </TableDetail><TableDetail>1 - 1603 62 Ave SE <br/>Calgary, AB  T2C 2C5</TableDetail></TableRow>
                        <TableRow><TableDetail>Phone: </TableDetail><TableDetail>403.236.7673</TableDetail></TableRow>
                    </TableBody>
                </Table>
            </TableBlock>
        </Section>
    )}