import React from "react";
import logo from "../../assets/logo.png";
import styled from 'styled-components';
import { Link } from "react-router-dom";


const lynnridgeLogoAlt = 'Lynnridge Laundry Logo'

const LynnridgeLogo = styled.img`
`

export const Logo : React.FC = () => {
    return (<Link to="/" ><LynnridgeLogo src={logo} alt={lynnridgeLogoAlt}></LynnridgeLogo></Link>)
}