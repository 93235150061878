import React from "react";
import styled from 'styled-components';
import { Section, Title, TableBlock, Table, TableBody, TableRow, TableDetail } from '../../styles/footerStyles'
import { Link } from 'react-router-dom'
import { routes } from "../../constants/routes";


const QuickLink = styled(Link)`
    text-decoration: none;
    color: unset;
`

export const QuickLinks: React.FC = () => {
    return (
        <Section>
            <Title>
                Quick Links
            </Title>
            <TableBlock>
                <Table>
                    <TableBody>
                        <TableRow><TableDetail><QuickLink to={routes.hoursandlocation}>Hours & Location</QuickLink></TableDetail></TableRow>
                        <TableRow><TableDetail><QuickLink to={routes.machines}>Machines & Prices</QuickLink></TableDetail></TableRow>
                    </TableBody>
                </Table>
            </TableBlock>
        </Section>
    )}