import React from "react";
import styled from 'styled-components';

import { ShadowBoxComponent } from "../../components/shadowBox/shadowBox";
import cleanShirts from "../../assets/images/cleanShirts.jpg";
import doneLaundry from "../../assets/images/doneLaundry.jpg";
import laundryMachine from "../../assets/images/laundryMachine.jpg";
import ThreeWideSection from "../../components/gridlayout/ThreeWide";

const ServicesGroup = styled(ThreeWideSection)`
`
const ServiceGridItem = styled(ShadowBoxComponent).attrs({className: 'LL-serviceGrid'})`
`

export const ServiceGrid : React.FC = () => {
    return (<ServicesGroup>
                <ServiceGridItem image={laundryMachine} imageAlt='Washine Machine' textHTML='Lynnridge Laundry has extended hours, so you can do your laundry at your own convenience.'/>    
                <ServiceGridItem image={cleanShirts} imageAlt='Clean Shirts' textHTML='Our oversized machines allow you to do many loads simultaneously, allowing you to get in and out with fresh clean clothes as quickly as possible.'/>
                <ServiceGridItem image={doneLaundry} imageAlt='Stack of Done Laundry' textHTML='With all our onsite amenities, the time you spend at our laundromat will not only be short but enjoyable as well!'/>   
            </ServicesGroup>
        )
}