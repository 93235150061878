import React from 'react'
import GoogleMapReact from 'google-map-react'
//import './map.css'
//import location from '../../constants/location'
import styled from 'styled-components';
import mapMarker from '../../assets/images/mapMarker.png'

interface LocationPinProps {
  lat: number;
  lng: number;
  text: string;
}

interface MapProps {
  location: {
    address: string;
    lat: number;
    lng: number;
  }
  zoomLevel: number;
  height?: string | '100vh';
  width?: string | '100%';
}

const LocationPinImage = styled.img`
`

const LocationPin : React.FC<LocationPinProps> = ( { lat, lng, text } ) => {
  return (
  <div className="pin" style={{position: 'absolute', transform: 'translate(-36%, -77%)'}}>
    <LocationPinImage src={mapMarker} className="pin-icon" />
    <p className="pin-text" style={{ fontWeight: 'bolder',marginLeft: 'auto', marginRight: 'auto', paddingRight: '3em', width: '12em'}}>{text}</p>
  </div>
  )}


  const apiKey = process.env.REACT_APP_GOOGLE_KEY || 'AIzaSyCtRHiMO9IV4_vZJFGwKLAE2h-7I9NbdnI';

export const Map : React.FC<MapProps> = ({ location, zoomLevel, height, width }) => {
  return(
    <div className="map" style={{ height, width, paddingLeft: '2em', paddingRight: '2em' }}> 
      <div className="google-map" style={{ height, width }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={location}
          defaultZoom={zoomLevel}
        >
          <LocationPin
            lat={location.lat}
            lng={location.lng}
            text={location.address}
          />
        </GoogleMapReact>
      </div>
    </div>
  )}