import { FunctionComponent } from "react";
import styled from 'styled-components';
import { Logo } from '../../components/logo/logo'
import { AddressWithIcon } from "../../components/address/address";
import { HolidayHours } from "../../components/hours/holidayHours";
import { Link } from 'react-router-dom'
import { routes } from "../../constants/routes";

const TopContainer = styled.div`
    padding-top: 0.75rem;
`
const MyNavBar = styled.nav`
    padding-top: 0.5rem;
    padding-bottom: 0.25rem;
    align-items: center;
    a{
        margin: 0.25rem;
        text-decoration: none;
        color: #2d3b97
    }
`

const QuickLink = styled(Link)`
    text-transform: uppercase;
    padding-right: 1px;
    padding-left: 1px;
    text-align: center;
    border: line;
    width: 10rem;
    height: 2rem;
`

const NavButton = styled.button`
    background-color: #fff;
    border: 0 solid #e2e8f0;
    box-sizing: border-box;
    color: #0EB2DE;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    text-align: center;
    text-decoration-thickness: auto;
    transition: all .1s cubic-bezier(.4, 0, .2, 1);
    box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
    user-select: none;
    -webkit-user-select: none;
    width: 10rem;
    height: 3rem;
    text-transform: uppercase;
    font-size: 12px;
    &:hover {
        background-color: #0EB2DE;
        color: #fff;
      }
`

export const lynnridgeLogoAlt = 'Lynnridge Laundry Logo'

export const HeaderSection : FunctionComponent = () => {
    return (
    <TopContainer as="header">
        <Logo/><br/>
        <AddressWithIcon/>
        <MyNavBar>
                <QuickLink to={routes.hoursandlocation}><NavButton>Hours & Location</NavButton></QuickLink>
                <QuickLink to={routes.machines}><NavButton>Machines & Prices</NavButton></QuickLink>
        </MyNavBar>
        <HolidayHours/>
    </TopContainer>
    )
}
