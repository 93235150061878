import React from "react";
import styled from 'styled-components';
import { ShadowBoxComponent, Box } from "../../components/shadowBox/shadowBox";
import { Row, Col } from 'react-grid-system';
import { FunctionComponent } from "react";
import ThreeWideSection from "../../components/gridlayout/ThreeWide";

import Double720_2 from "../../assets/images/equipment/Double720_2.jpg";
import Doubles_2 from "../../assets/images/equipment/Doubles_2.jpg";
import Quads_1 from "../../assets/images/equipment/Quads_1.jpg";
import TD50_1 from "../../assets/images/equipment/TD50_1.jpg";
import Vended_1 from "../../assets/images/equipment/Vended_1.jpg";
import GoToTop from "../../components/goToTop/goToTop";
import TD35_1 from "../../assets/images/equipment/TD35_1.jpg";



const OldDoublesDescription = 'The doubles are good as an all around machine when you want to do your laundry and get out again. They can fit a bit of a larger load when you don’t quite have enough for a larger machine. Average run time is 35 minutes per load.</br>We have 4 of these.'
const QuadsDescription = '<b>These are big! King size comforter, no problem.</b></br></br>These are extremely popular because they can hold quite a bit of laundry. Recommended for quilts, blankets, sleeping bags and other bulky items or if you happen to have a large load you want to get done at once.</br>Average run time is 35 minutes per load.</br>We have 3 of these.'
const NewDoublesDescription = '<b>We now have 2 of these!</b></br>Slightly larger than our other double machines. This one has the option of an extra rinse and extra wash cycle.</br>Average run time is 35 minutes per load. </br>Pro Tip: Washer 7 has a slightly shorter wash time and a stronger spin, potentially reducing dry time.'
const Dryer35Description = 'We currently have 5 of these. They are newer dryers that are workhorses and pair well with our smaller washers.</br>Temperature is adjustable from high down to no heat.'
const Dryer50Description = 'These stacked dryers can handle a full 20Kg (45lb) load and are matched in size to our largest washer. They have a handy feature for blankets that automatically reverses direction to help reduce the rolling that occurs when drying quilts, comforters, sleeping bags and other large items. They are bigger in person, come check them out.</br>Temperature is adjustable from high down to no heat.'
const ChangeMachineDescription = 'We have single serve vended soap, dryer sheets and fabric softener.</br></br> <center><h4>Change Machine</h4></center></br>While not a washer or dryer, our change machine is here to serve you. It will provide both Loonies and Quarters. It accepts bills up to $20, plus Toonies ($2 coin) and provide Loonies ($1 coin). It will also accept Loonies and provide Quarters.'

const PageContainer = styled.section`
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
`
const SectionHeader  = styled.h2`
`

const PricingGroup = styled(ThreeWideSection)`
    ${Box} {
        border: none;
    }
`
const MachinesGroup = styled(ThreeWideSection)`
`

const MachineSection = styled(ShadowBoxComponent).attrs({className: 'LL-MachineDescription'})`
`
const PricingContainer = styled.section`
`
const PricingColLeft = styled(Col)`
    text-align: left;
    min-width: 75%;
`
const PricingColRight = styled(Col)`
`

const WasherPricing: FunctionComponent = () => {
    return (<PricingContainer>
            <h3>Washers</h3>
            <Row>
                <PricingColLeft>Double Machine</PricingColLeft><PricingColRight>$4.00</PricingColRight>
            </Row>
            <Row>
                <PricingColLeft>Newer Double Machines</PricingColLeft><PricingColRight>$5.00</PricingColRight>
            </Row>
            <Row>
                <PricingColLeft>“Quad” Machine</PricingColLeft><PricingColRight>$9.00</PricingColRight>
            </Row>
        </PricingContainer>
    )
}

const DryerPricing: FunctionComponent = () => {
    return (<PricingContainer>
            <h3>Dryers</h3>
            <Row>
                <PricingColLeft>Large Dryers (10 Minutes) </PricingColLeft><PricingColRight>$1.00</PricingColRight>
            </Row>
            <Row>
                <PricingColLeft>Regular Dryers (15 Minutes)</PricingColLeft><PricingColRight>$1.00</PricingColRight>
            </Row>
        </PricingContainer>
    )
}

const VendedPricing: FunctionComponent = () => {
    return (<PricingContainer>
            <h3>Vended Supplies</h3>
            <Row>
                <PricingColLeft>Tide Single Use Box</PricingColLeft><PricingColRight>$1.00</PricingColRight>
            </Row>
            <Row>
                <PricingColLeft>OxyClean Single Use Box</PricingColLeft><PricingColRight>$1.00</PricingColRight>
            </Row>
            <Row>
                <PricingColLeft>Bounce Sheets (2 Per Box)</PricingColLeft><PricingColRight>$1.00</PricingColRight>
            </Row>
            <Row>
                <PricingColLeft>Liquid Fabric Softener</PricingColLeft><PricingColRight>$1.00</PricingColRight>
            </Row>
        </PricingContainer>
    )
}

export const  Machines : React.FC = () => {
    return (
                <PageContainer>
                    <SectionHeader>Self Serve Vended Prices</SectionHeader>
                    <PricingGroup>
                        <WasherPricing />
                        <DryerPricing />
                        <VendedPricing />
                   </PricingGroup>
                    <SectionHeader>Our Machines</SectionHeader>
                    <MachinesGroup>
                        <MachineSection title='LARGE! "Quad" Washer (WLD745CC)' image={Quads_1} imageAlt='Quad Sized Washine Machine' textHTML={QuadsDescription}/>
                        <MachineSection title='Newer Double Washer (W720)' image={Double720_2} imageAlt='New Double Sized Washine Machine' textHTML={NewDoublesDescription}/>
                        <MachineSection title='Double Washer (Wascomat W75)' image={Doubles_2} imageAlt='Double Sized Washine Machine' textHTML={OldDoublesDescription}/>
                        <MachineSection title='Large Dryers (TD50)' image={TD50_1} imageAlt='TD50 Large Dryer' textHTML={Dryer50Description}/>
                        <MachineSection title='Regular Dryers (TD35)' image={TD35_1} imageAlt='TD35 Dryer' textHTML={Dryer35Description}/>
                        <MachineSection title='Vending Machine' image={Vended_1} imageAlt='Vending Machine with single use items' textHTML={ChangeMachineDescription}/>
                    </MachinesGroup>
                    <GoToTop/>
                </PageContainer>
        )
}
