import React from "react";
import styled from 'styled-components';
import GoToTop from "../../components/goToTop/goToTop";

const PageContainer = styled.section`
    width: 100%;
    height: 100%;
    padding: 2em;
`

const PolicyHeader = styled.h2`
    text-align: left;
`

const PolicyText = styled.p`
    margin: 0 0 10px;
    line-height: 1.7;
    text-align: justify;
`

export const  PrivacyPolicy : React.FC = () => {
    return (
                <PageContainer>
                    <PolicyText>Welcome to lynnridgelaundry.com. Before using this Site please read the terms and conditions carefully. By accessing, browsing and using this Site, you are signifying your consent to be bound by the Terms and Conditions. The Terms and Conditions and the related documents will be revised from time to time. We recommend that you review these documents regularly, to ensure you are aware of any revisions.</PolicyText>
                    <PolicyHeader>Introduction</PolicyHeader>
                    <PolicyText>At Lynnridge Laundry we believe that when you visit the lynnridgelaundry.com website (the “Site”), your personal information deserves protection. Lynnridge Laundry has prepared this Privacy Policy to let you know what we can, and cannot do with your personal information, and to ensure that you understand our commitment to providing you with a secure and safe experience. </PolicyText>
                    <PolicyText>It is always your decision as to whether you wish to use the Site and provide us with information about yourself. Unless you give it to us we will not record or use your personal information.</PolicyText>
                    <PolicyHeader>Collection Of Personal Information</PolicyHeader>
                    <PolicyText>You may visit the Site without registering and directly providing any personal information. However you will need to register and provide personal information if you wish to access particular portions of the Site and to use specific functions that are available to you. In other instances, such as when sending Lynnridge Laundry an email or providing us with comments, we may require that you provide personal information such as your e-mail address.</PolicyText>
                    <PolicyHeader>Use of Personal Information by Lynnridge Laundry</PolicyHeader>
                    <PolicyText>Lynnridge Laundry may use the personal information provided in the following ways:</PolicyText>
                    <PolicyText>To build and maintain your relationship with Lynnridge Laundry</PolicyText>
                    <PolicyText>To process service requests and other types of orders.</PolicyText>
                    <PolicyText>To personalize the delivery of content and information to you.</PolicyText>
                    <PolicyText>Lynnridge Laundry may also use personal information and data collected to generate aggregate statistics and reports for internal use and for sharing with legitimate statistics gathering organizations. These statistics and reports will not contain any information that would identify individual users of the Site.</PolicyText>
                    <PolicyHeader>Disclosure of Personal Information</PolicyHeader>
                    <PolicyText>Visitors' non-aggregated specific personal and account information will not be sold, rented or made available to anyone other than Lynnridge Laundry unless you have given us express permission to do so.</PolicyText>
                    <PolicyText>Lynnridge Laundry reserves the right to co-operate with law enforcement and other officials in any investigation requiring either personal or account information, including any information provided online through the Site, or reports about lawful or unlawful user activities on the Site. Lynnridge Laundry also reserves the right at any time to disclose any personal and account information: (i) to comply with any applicable law or regulation; (ii) if such disclosure is necessary or appropriate to site operation; or (iii) to protect the rights or property of Lynnridge Laundry and its users, partners, providers, licensors or merchants.</PolicyText>
                    <PolicyHeader>Revisions to Privacy Policy</PolicyHeader>
                    <PolicyText>If Lynnridge Laundry needs to change this Privacy Policy, changes will be posted on this Site, so that users are always aware of what information is collected, how it is used, and under what circumstances it may be disclosed.</PolicyText>
                    <PolicyHeader>Consent</PolicyHeader>
                    <PolicyText>By using the Site, the user consents to the collection and use of personal and account information by Lynnridge Laundry, in accordance with this Privacy Policy.</PolicyText>
                    <PolicyHeader>Questions or Comments</PolicyHeader>
                    <PolicyText>Lynnridge Laundry welcomes your feedback regarding the Site and this Privacy Policy. Please contact us by e-mail at washmyclothes@lynnridgelaundry.com or by phone 403.236.7673</PolicyText>
                    <GoToTop/>
                </PageContainer>
        )
}
