
import styled from 'styled-components';

export const Section = styled.div`
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
    top: 0;
    text-align: left;
    transition: all 0.3s ease-in-out;
`
export const Title = styled.h3`
    color: #fff;
    font-size: 1.1em;
    font-weight: 700;
    margin-bottom: 0.75em;
    text-transform: uppercase;
`

export const TableBlock = styled.div`
    display: inline-block;
`

export const Table = styled.table`
    color: #C2C2C2;
    vertical-align: top;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;
    display: table;
    text-indent: initial;
    border-color: grey;
`

export const TableBody = styled.tbody`
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
`

export const TableRow = styled.tr`
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
`
export const TableDetail = styled.td`
    display: table-cell;
    vertical-align: top;
    padding: 5px 10px 5px 0;
    vertical-align: top;
`