import React from 'react';
import './App.css';
import { Homepage } from './containers/homepage';
import { BrowserRouter as Router } from "react-router-dom";

const App: React.FC = () => {
  return (<Router>
    <Homepage />
    </Router>)
}

export default App;
