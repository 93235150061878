import React from "react";
import styled from 'styled-components';

interface ShadowBoxProps {
    image?: string,
    imageAlt?: string,
    textHTML?: string,
    title?: string,
    className: string,
}

export const Box = styled.div`
    border: 1px dashed #e6e6e6;
    padding-bottom: 1em;
    padding-top: 1em;
    padding: 1em;
    position: relative;
    top: 0;
    text-align: center;
    transition: all 0.3s ease-in-out;
`
const Image = styled.img`
    max-width: 100%;
    max-height: 80vh;
`
const Text = styled.main`
    text-align: left;
`

const Title = styled.h4`
    text-align: center;
    padding-bottom: 0.5em;
`




export const ShadowBoxComponent: React.FC<ShadowBoxProps> = ({className, image, imageAlt, textHTML, title}) => {
    return (
        <Box className={className}>
            {title && (
            <Title>
                {title}
            </Title>)}
            {image && (<Image src={image} alt={imageAlt}/>)}
            {textHTML && (
            <Text>
                <p dangerouslySetInnerHTML={{__html: textHTML}}/>
            </Text>)}
        </Box>
    )}