import styled from "styled-components";

const TwoWideSection = styled.section`
    padding: 0.5em 2rem;
    text-align: unset;
    display: grid;
    grid-template-rows: max-content;
    margin: 0;
    justify-content: center;

    @media (max-width: 768px) {
        grid-column: 1 / 12;
        grid-template-columns: repeat(1, 1fr);
        gap: 0.5rem 0.5rem;
        margin: 0;
        }


    @media (min-width: 768px) {
        grid-column: 2 / 12;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem 0.5rem;
        margin: 0;
        }
    }
`

export default TwoWideSection;