import React from "react";
import slider3 from "../../assets/images/slider/slider3.jpg"
import styled from 'styled-components';

const lynnridgeSliderAlt = 'Enjoy onsite amenities, self serve coin operated machines, soap and change machines, free wifi.'

const LynnridgeSlider = styled.img`
    max-width: 90%;
    margin: 0;
    max-height: 80vh;
    padding-top: 1em;
`

export const Slider : React.FC = () => {
    return (<LynnridgeSlider src={slider3} alt={lynnridgeSliderAlt}>
        </LynnridgeSlider>)
}