import { FunctionComponent } from "react";
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import config from '../../config/config';

const ShowHolidayHours = config.SHOWHOLIDAYHOURS;


const HolidayHoursStyle = styled.div`
    padding: 0.5em;
`
/*
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    flex: 1;

const StyledContainer = styled(Container)`
    display: flex; 
    flex-direction: row; 
    justify-content: flex-start; 
    align-items: center; 
    flex: 1;
    width: 100%;

    const StyledContainer = styled(Container)`
    width: 350px;
    background: papayawhip;
    padding: 0.25em 1em;
`
`
*/
const StyledContainer = styled(Container)`
    width: 350px;
    background: #e6f7fb;
    padding: 0.50em 0.5em; 
    text-align: center;
`

const HoursHeader = styled.h4`
    font-weight: bold;
    color: #E47928;
    margin-bottom: 0.3em
`

const HoursDivider = styled.hr`
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
`

export const HolidayHours : FunctionComponent = () => {
    if (ShowHolidayHours) {
        return(<HolidayHoursStyle>
                <StyledContainer>
                    <HoursHeader>2023 Holiday Hours</HoursHeader>
                    <Row>
                        <Col>December 24, 2023</Col><Col>7:00am to 2:00pm</Col>
                    </Row>                  
                    <Row>
                        <Col>December 25, 2023</Col><Col>CLOSED</Col>
                    </Row>
                    
                    <Row>
                        <Col>December 26, 2023</Col><Col>7:00am to 10:00pm</Col>
                    </Row>
                    <Row>
                        <Col>December 31, 2023</Col><Col>7:00am to 4:00pm</Col>
                    </Row>
                    <Row>
                        <Col>January 1, 2024</Col><Col>CLOSED</Col>
                    </Row>
                    <HoursDivider></HoursDivider>
                    <Row>
                        <Col>Last wash 90 minutes before close</Col>
                    </Row>
                    <HoursDivider></HoursDivider>
                    <Row>
                        <Col>Reentry up to 60 minutes before close</Col>
                    </Row>
                    <HoursDivider></HoursDivider>
                    <Row>
                        <Col>Regular Hours</Col><Col>7:00am to 10:00pm</Col>
                    </Row>
                </StyledContainer>
            </HolidayHoursStyle>)  
    }
    else {
        return null
    }
}