import styled from 'styled-components';
import { Slider } from "../../components/slider/slider"
import { ServiceGrid } from "./serviceGrid"

const PageContainer = styled.div`
`

export const  MainContent : React.FC = () => {
    return (<PageContainer>
                    <Slider></Slider>
                    <ServiceGrid></ServiceGrid>
                </PageContainer>
)}
