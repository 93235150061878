import React from "react";
import styled from 'styled-components';
import ThreeWideSection from "../../components/gridlayout/ThreeWide";
import { Location } from "../../components/location/location";
import { FollowUs } from "../../components/followUs/followUs";
import { QuickLinks } from "../../components/quickLinks/quickLinks";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";

const FooterGroup = styled.div`
    border-top: 7px solid #E47928;
    background-color: #000;
    padding: 0.7em 0;
`
const FooterContent = styled(ThreeWideSection)`
`
const FooterCopyright = styled.div`
    color: #C2C2C2;
    font-size: 0.8rem;
`

const CopyRightLink = styled(Link)`
    color: #C2C2C2;
    padding-right: 3px;
    padding-left: 3px;
    text-align: center;
    text-decoration: none;
`



export const Footer : React.FC = () => {
    return (<FooterGroup>
                <FooterContent>
                    <Location/>
                    <QuickLinks/>
                    <FollowUs/> 
                </FooterContent>
                <FooterCopyright>
                    Copyright © 2021 Lynnridge Laundry Inc. All Rights Reserved. | 
                    <CopyRightLink to={routes.privacypolicy}>Privacy Policy</CopyRightLink> | 
                    <CopyRightLink to={routes.termsofuse}>Website Terms of Use</CopyRightLink>
                </FooterCopyright>
            </FooterGroup>
        )
}