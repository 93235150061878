import styled from "styled-components";

const ThreeWideSection = styled.section`
    padding: 0.5em 1.5rem;
    text-align: unset;
    grid-column: 1 / 12;
    display: grid;
    grid-template-columns: repeat(12, minmax(auto, 4.2rem));
    grid-template-rows: max-content;
    gap: 2rem 2rem;
    margin: 0;
    justify-content: center;

    @media (max-width: 768px) {
        grid-column: 2 / 12;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem 1rem;
        margin: 0;
        }

    @media (min-width: 768px) {
    grid-column: 2 / 12;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 1rem;
    margin: 0;
    }

    @media (min-width: 992px) {
        grid-column: 2 / 12;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem 1rem;
        margin: 0;
        }
    }
`

export default ThreeWideSection;