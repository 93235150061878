import React from "react";
import styled from 'styled-components';
import { Section, Title, TableBlock, Table, TableBody, TableRow, TableDetail } from '../../styles/footerStyles'

import iconSocial from "../../assets/images/iconSocial.png"

const FacebookLinked = styled.a`
    color: #C2C2C2;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
`
const FaceBookLinkURL = '//www.facebook.com/lynnridgelaundry/'
const FacebookText = ' Facebook'

// const FacebookIcon = styled.img`
//     background-repeat: no-repeat;
//     display: inline-block;
//     font-size: 0;
//     height: 28px;
//     margin-right: 5px;
//     position: relative;
//     vertical-align: middle;
//     width: 27px;
//     background-color: transparent;
//     transition: all 0.3s ease-in-out;
//     cursor: pointer;
//     text-decoration: none;
// `

const SocialIcon = styled.span`
    background-repeat: no-repeat;
    display: inline-block;
    font-size: 0;
    height: 28px;
    margin-right: 5px;
    position: relative;
    vertical-align: middle;
    width: 27px;
    background-position: 0 0;

`
export const FollowUs: React.FC = () => {
    return (
        <Section>
            <Title>
                Follow Us
            </Title>
            <TableBlock>
                <Table>
                    <TableBody>
                        <TableRow><TableDetail><FacebookLinked href={FaceBookLinkURL}><SocialIcon style={{ backgroundImage: `url(${iconSocial})` }}/>{FacebookText}</FacebookLinked></TableDetail></TableRow>
                    </TableBody>
                </Table>
            </TableBlock>
        </Section>
    )}