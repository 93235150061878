import { createGlobalStyle } from "styled-components";


//        text-align: center;
//         align-items: center;
export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root {
      font-size: 16px;
    }

    html, body {
        height: 100%;
    }

`;