
import styled from 'styled-components';
import { HeaderSection } from "./headerSection";
import Theme from "../../styles/theme";
import { GlobalStyles } from "../../styles/gloablStyles";
import { ThemeProvider } from "styled-components";
import { Footer } from "./footer";
import { MainContent } from "./mainContent";
import { Route, Routes, Navigate } from "react-router-dom";
import { routes } from "../../constants/routes"
import { HoursAndLocation } from "../hoursLocationPage/hoursLocation";
import { Machines } from "../machinesPage/machines";
import { PrivacyPolicy } from "../privacyPolicyPage";
import { TermsOfUse } from "../termsOfUsePage";

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
`


export const  Homepage : React.FC = () => {
    return (<ThemeProvider theme={Theme}>
                <GlobalStyles/>
                <PageContainer>
                    <HeaderSection />
                        <Routes>
                            <Route path={routes.root} element={<MainContent />} />
                            <Route path={routes.hoursandlocation} element={<HoursAndLocation />} />
                            <Route path={routes.machines} element={<Machines />} />
                            <Route path={routes.privacypolicy} element={<PrivacyPolicy />} />
                            <Route path={routes.termsofuse} element={<TermsOfUse />} />
                            <Route path="/*" element={<Navigate replace to="/" />} />
                        </Routes>
                    <Footer />
                </PageContainer>
            </ThemeProvider>
            )
}
