import styled from 'styled-components';
import GoToTop from '../../components/goToTop/goToTop';
import TwoWideSection from "../../components/gridlayout/TwoWide";
import { Map } from "../../components/map/map";
import maplocation from "../../constants/location";


const PageContainer = styled.section`
    width: 100%;
    height: 100%;
    text-align: center;
    align-items: center;
    padding-bottom: 1em;
`

const RegularHours = styled(TwoWideSection)`
`

const RegularHoursLeft = styled.div`
`
const RegularHoursRight = styled.div`
    border: 1px solid #ffd35a;
    background-color: #fcebbf;
`
const RegularHourText = styled.h3`
`
const ReentryText = styled.h4`
    padding: 0.5em;
`
const LocationDiv = styled.div`
    padding-bottom: 1em;
`

const LocationText = styled.a`
    color: #7F7F7F;
    font-style: italic;
`
const LocationMap = styled(Map)`
    padding: 2em;
`

const HowToGetHere = styled.div`
`

const HowToGetHereItem = styled.a.attrs({
    target: '_blank',
    rel: 'noopener noreferrer'
})`
    text-decoration: none;
`

const SectionTitle = styled.h2`
`

export const  HoursAndLocation : React.FC = () => {
    return (
            <PageContainer>
                <SectionTitle>Regular Hours</SectionTitle>
                <RegularHours>
                        <RegularHoursLeft>
                            <RegularHourText>
                                Open 7 Days Per Week<br/>
                                7:00am to 10:00pm
                            </RegularHourText>
                        </RegularHoursLeft>
                        <RegularHoursRight>
                            <ReentryText>
                                Last Wash is at 8:30pm.<br/>
                                Door Locks at 9:00pm for ReEntry
                            </ReentryText>
                        </RegularHoursRight>
                </RegularHours>
                Open Most Holidays, Except Around Christmas
                <LocationDiv>
                    <SectionTitle>Location</SectionTitle>
                    <LocationText>
                        1-1603 62 Ave SE<br/>
                        Calgary, Alberta T2C 2C5
                    </LocationText>
                    <LocationMap location={maplocation} zoomLevel={13} height={'75vh'}>
                    </LocationMap>
                </LocationDiv>
                <SectionTitle>How To Get Here From...</SectionTitle>
                <HowToGetHere>
                    <div><HowToGetHereItem href='//goo.gl/maps/R3Vde'>Glenmore Inn</HowToGetHereItem></div>
                    <div><HowToGetHereItem href='//goo.gl/maps/oAWS7'>Blackfoot Inn</HowToGetHereItem></div>
                    <div><HowToGetHereItem href='//goo.gl/maps/VLy6R'>Holiday Inn (Macleod)</HowToGetHereItem></div>
                    <div><HowToGetHereItem href='//goo.gl/maps/yp4Hu'>Sandmann Inn (Deerfoot Meadows)</HowToGetHereItem></div>
                </HowToGetHere>
                <GoToTop/>
            </PageContainer>
            )
}
