export interface StageConfig {
    SHOWHOLIDAYHOURS: boolean;
  }
  
  interface SharedConfig {
    REGION: string;
  }

type Config = SharedConfig & StageConfig;

function loadStageConfig(stage: string): StageConfig {
  // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires,
  const env = require('./' + stage + '.ts');
  return env.default;
}

const sharedConfig: SharedConfig = {
  REGION: 'us-west-2'
};

const stageConfig = loadStageConfig(process.env.REACT_APP_STAGE || 'staging');

const config: Config = {
  ...sharedConfig,
  ...stageConfig,
};

export default config;
